<script setup lang="ts">
import { ProductTabProps } from "./ProductTab.props"
import { mdOptions, smOptions } from "./ProductTab.carouselOptions"
import { sm } from "~/utils/breakpoints"
import { VerticalProps } from "~/components/ProductTile/Overall/Vertical.props"

const props = defineProps<ProductTabProps>()
const currentOptions = computed(() => {
  return sm.value ? smOptions : mdOptions
})

const notEmptyTabs = props.tabs

notEmptyTabs.tabsList = notEmptyTabs.tabsList.filter((tab: any) => {
  return tab.content.length > 0
})
</script>

<template>
  <div class="carousel-container-overflowed mt-5">
    <TheTabs :="notEmptyTabs" tabLayout="start" isPadded>
      <template #tabs="{ selectedTab }">
        <div
          class="padded my-5"
          v-for="(t, i) in notEmptyTabs.tabsList"
          :key="`${t.id}-${i}`"
          v-show="selectedTab === i"
        >
          <GAListCarouselProductRailsTab :name="t?.text">
            <GAItem :id="t?.text" :item="(t?.content as VerticalProps[])">
              <GAViewItemList>
                <AppCarousel
                  v-if="(t?.content as VerticalProps[])?.length"
                  class="carousel-overflowed select-none"
                  :items="t?.content as VerticalProps[]"
                  :swiper-props="currentOptions"
                  :theme="currentOptions?.theme"
                  :key="sm ? 1 : 0"
                >
                  <template #default="{ item }">
                    <ProductTileOverallVertical
                      v-if="!item.cardType"
                      v-bind="item"
                      class="swiper-tile"
                      :disableCallToActionOnCard="
                        props.disableCallToActionOnCard
                      "
                    />
                    <div v-else class="swiper-tile h-full w-[242px]">
                      <BannerDiscountPromoHorizontal fullHeight v-bind="item" />
                    </div>
                  </template>
                </AppCarousel>
              </GAViewItemList>
            </GAItem>
          </GAListCarouselProductRailsTab>
        </div>
      </template>
    </TheTabs>
  </div>
</template>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}
.swiper-tile {
  margin: 0 8px;
}

@screen md {
  .swiper-tile {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 4) - 24px);
  }
}

@screen lg {
  .swiper-tile {
    width: calc(((100vw - 456px) / 4) - 24px);
  }
}

</style>
